import { Button, ButtonGroup, Forms, H3, P } from "@/components/DesignSystem";
import { t } from "@/translations";
import React from "react";

const {
    useForm,
    Form,
    Fields,
    SubmitButton,
    validators,
    pmValidators,
    success,
    error,
} = Forms;

const checkExistingPartitionName = partitionNamesList => value =>
    value && partitionNamesList.includes(value)
        ? error(t("partition-request.validation.partition-name-already-exists"))
        : success();

const createPurposeOptions = (purposeList = []) =>
    purposeList?.map(purpose => ({
        label: t(`partition-request.radio.purpose.${purpose.toLowerCase()}`),
        value: purpose,
    }));

export const PartitionClonePage = ({
    accountId,
    partitionId,
    clustersList,
    contactsList,
    onCancel,
    onPurposeChange,
    onSubmit,
    partitionNamesList,
    purposeList,
}) => {
    const onSubmitMapper = ({ values }) => {
        onSubmit({ ...values, accountId });
    };

    const { formId, handleSubmit, setValues } = useForm({
        onSubmit: onSubmitMapper,
    });

    const handlePurposeChange = event => {
        setValues({ clusterId: undefined });
        onPurposeChange(event);
    };

    return (
        <>
            <H3>{t("partition-clone.title")}</H3>
            <P>{t("partition-clone.info")}</P>
            <Form formId={formId} onSubmit={handleSubmit}>
                <Fields.Input
                    required
                    name="name"
                    label={t("partition-request.label.name")}
                    placeholder={t("partition-request.placeholder.name")}
                    validator={validators.failOnFirst([
                        pmValidators.isRequired,
                        pmValidators.noWhiteSpaces,
                        checkExistingPartitionName(partitionNamesList),
                    ])}
                />

                <Fields.Input
                    required
                    name="label"
                    label={t("partition-request.label.label")}
                    placeholder={t("partition-request.placeholder.label")}
                    tooltip={t("partition-request.tooltip.label")}
                    validator={pmValidators.isRequired}
                />

                <Fields.TextArea
                    name="description"
                    label={t("partition-request.label.description")}
                    placeholder={t("partition-request.placeholder.description")}
                    rows="3"
                    dataTest="create-partition-description"
                />

                <Fields.Select
                    required
                    name="purpose"
                    allowClear={false}
                    label={t("partition-request.label.purpose")}
                    onChange={handlePurposeChange}
                    options={createPurposeOptions(purposeList)}
                    placeholder={t("partition-request.placeholder.purpose")}
                    showSearch
                    validator={pmValidators.isRequired}
                />

                <Fields.Select
                    required
                    disabled={!clustersList.length}
                    name="clusterId"
                    label={t("partition-request.label.cluster")}
                    options={clustersList?.map(cluster => ({
                        label: cluster.optionLabel,
                        value: cluster.id,
                    }))}
                    placeholder={t("partition-request.placeholder.cluster")}
                    validator={pmValidators.isRequired}
                />

                <Fields.Select
                    name="contact"
                    label={t("partition-request.label.contact")}
                    placeholder={t("partition-request.placeholder.contact")}
                    options={contactsList?.map(contact => ({
                        label: contact.name,
                        value: contact.contactId,
                    }))}
                />

                <ButtonGroup>
                    <SubmitButton>
                        <Button
                            label={t("partition-request.button.submit")}
                            type="primary"
                            htmlType="submit"
                        />
                    </SubmitButton>
                    <Button
                        type="text"
                        onClick={onCancel}
                        label={t("general.cancel")}
                    />
                </ButtonGroup>
            </Form>
        </>
    );
};
