import { getData } from "@/services/utils";
import qs from "qs";
import { axiosService } from "../axios";
const qsOptions = { addQueryPrefix: true };

const ISV_MAPPINGS_URL = "/api/isv-mappings";
export const isvMappingService = {
    listMappers: connectionId =>
        axiosService
            .get(`${ISV_MAPPINGS_URL}?connectionId=${connectionId}`)
            .then(getData),
    createMapper: mapper =>
        axiosService.post(`${ISV_MAPPINGS_URL}`, mapper).then(getData),
    changeStatus: (connectionId, isEnabled) =>
        axiosService
            .patch(`${ISV_MAPPINGS_URL}/${connectionId}`, {
                enabled: isEnabled,
            })
            .then(getData),

    getVendorEntityTypes: ({ direction, vendor }) =>
        axiosService
            .get(`${ISV_MAPPINGS_URL}/${direction}/${vendor}/entity-types`)
            .then(getData),
    getMapper: mapperId =>
        axiosService.get(`${ISV_MAPPINGS_URL}/${mapperId}`).then(getData),

    editMapper: (mapperId, mapper) =>
        axiosService
            .post(`${ISV_MAPPINGS_URL}/${mapperId}`, mapper)
            .then(getData),

    deleteMapper: mapperId =>
        axiosService.delete(`${ISV_MAPPINGS_URL}/${mapperId}`).then(getData),
    connectionNameExists: (connectionId, connectionName) => {
        const queryStr = qs.stringify(
            {
                connectionId,
                name: connectionName,
            },
            qsOptions,
        );

        return axiosService.get(`${ISV_MAPPINGS_URL}${queryStr}`).then(getData);
    },
};
