import { Forms, Gap } from "@/components/DesignSystem";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import {
    ISV_VENDOR,
    VendorEntity,
} from "@/components/ISVMapping/steps/vendor-entities";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React from "react";

const ArrowDown = () => {
    return (
        <>
            <Gap size="small" />
            ↓
            <Gap size="small" />
        </>
    );
};

const EntitiesStep = ({ form, allStepsProps }) => {
    const { isvConnection } = allStepsProps;

    const formId = Forms.useFormId();

    const entityType = useFieldValue({ formId, name: "type" });

    if (!entityType || !isvConnection.vendor) return null;
    return (
        <>
            <div style={{ maxWidth: "578px" }}>
                {entityType === MAPPER_DIRECTION.UPLOAD ? (
                    <>
                        <VendorEntity
                            vendor={isvConnection.vendor}
                            direction={entityType}
                            isvConnection={isvConnection}
                        />
                        <ArrowDown />
                        <VendorEntity
                            vendor={ISV_VENDOR.PRICEFX}
                            direction={entityType}
                            setValues={form.setValues}
                            isvConnection={isvConnection}
                        />
                    </>
                ) : (
                    <>
                        <VendorEntity
                            vendor={ISV_VENDOR.PRICEFX}
                            direction={entityType}
                            setValues={form.setValues}
                            isvConnection={isvConnection}
                        />
                        <ArrowDown />
                        <VendorEntity
                            vendor={isvConnection.vendor}
                            direction={entityType}
                            isvConnection={isvConnection}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default EntitiesStep;
