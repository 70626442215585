import { Forms, UnityHeading } from "@/components/DesignSystem";
import { GreyForm } from "@/components/ISVMapping/GreyForm.component";
import { SOURCE_TYPE } from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useEntityNamesQuery,
    useSupportedEntityTypesQuery,
} from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/loadables";
import { hasSubFilter } from "@/constants/SupportedTableImportTypes";
import { t } from "@/translations";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import React from "react";

export const PricefxEntity = ({ setValues, isvConnection }) => {
    const entityTypesQuery = useSupportedEntityTypesQuery({
        sourceType: SOURCE_TYPE.PARTITIONS,
        sourceId: isvConnection.partitionId,
    });
    const formId = Forms.useFormId();

    const entityType = useFieldValue({ formId, name: "pfxEntityType" });

    const entityNamesQuery = useEntityNamesQuery({
        entityType,
        sourceType: SOURCE_TYPE.PARTITIONS,
        sourceId: isvConnection.partitionId,
    });

    const entityNameRequired = hasSubFilter(entityType);
    return (
        <GreyForm>
            <UnityHeading size={4}>
                {t("isv-connections.data-mapping.step.entities.entity", {
                    vendorName: "Pricefx",
                })}
            </UnityHeading>
            <Forms.FieldGroup grid>
                <Forms.Fields.Select
                    required
                    name="pfxEntityType"
                    label={t(
                        "isv-connections.data-mapping.step.entities.entity-type",
                        {
                            vendorName: "Pricefx",
                        },
                    )}
                    allowClear={false}
                    showSearch
                    onChange={() => {
                        setValues({ pfxEntityName: undefined });
                    }}
                    validator={Forms.pmValidators.isRequired}
                    {...getLoadableSelectProps(entityTypesQuery.loadable)}
                />
                {entityNameRequired && (
                    <Forms.Fields.Select
                        required
                        name="pfxEntityName"
                        label={t(
                            "isv-connections.data-mapping.step.entities.table",
                            {
                                vendorName: "Pricefx",
                            },
                        )}
                        allowClear={false}
                        showSearch
                        validator={Forms.pmValidators.isRequired}
                        {...getLoadableSelectProps(entityNamesQuery.loadable)}
                    />
                )}
            </Forms.FieldGroup>
        </GreyForm>
    );
};
