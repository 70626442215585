import { t } from "@/translations";

export const pricingParameterValues = [
    "MLTV",
    "MLTV2",
    "MLTV3",
    "MLTV4",
    "MLTV5",
    "MLTV6",
    "LTV",
    "JLTV",
    "JLTV2",
];

export const ENTITY_TYPE_CODES_FE = {
    C: {
        value: "C",
        subFilter: false,
        label: t("supported-table-import-type.label.customer"),
    },
    CRCS: {
        value: "CRCS",
        subFilter: true,
        label: t("supported-table-import-type.label.condition-records"),
    },
    CX: {
        value: "CX",
        subFilter: true,
        label: t("supported-table-import-type.label.customer-extension"),
    },
    PBOME: {
        value: "PBOME",
        subFilter: false,
        label: t("supported-table-import-type.label.bom-data"),
    },
    DM: {
        value: "DM",
        subFilter: true,
        label: t("supported-table-import-type.label.data-mart"),
    },
    DMDS: {
        value: "DMDS",
        subFilter: true,
        label: t("supported-table-import-type.label.data-source"),
    },
    P: {
        value: "P",
        subFilter: false,
        label: t("supported-table-import-type.label.product"),
    },
    PX: {
        value: "PX",
        subFilter: true,
        label: t("supported-table-import-type.label.product-extension"),
    },
    PXREF: {
        value: "PXREF",
        subFilter: false,
        label: t("supported-table-import-type.label.product-reference"),
    },
    MLTV: {
        value: "MLTV",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    PCOMP: {
        value: "PCOMP",
        subFilter: false,
        label: t("supported-table-import-type.label.competition-data"),
    },
    SL: {
        value: "SL",
        subFilter: false,
        label: t("supported-table-import-type.label.seller"),
    },
    SX: {
        value: "SX",
        subFilter: true,
        label: t("supported-table-import-type.label.seller-extension"),
    },
    LPG: {
        value: "LPG",
        subFilter: true,
        label: t("supported-table-import-type.label.live-price-grid"),
    },
    XLPG: {
        value: "XLPG",
        subFilter: true,
        label: t("supported-table-import-type.label.x-live-price-grid"),
    },
    XPLI: {
        value: "XPLI",
        subFilter: true,
        label: t("supported-table-import-type.label.x-price-list"),
    },
    U: {
        value: "U",
        subFilter: false,
        label: t("supported-table-import-type.label.user"),
    },
    PLI: {
        value: "PLI",
        subFilter: true,
        label: t("supported-table-import-type.label.price-lists"),
    },
    MPLI: {
        value: "MPLI",
        subFilter: true,
        label: t("supported-table-import-type.label.manual-price-lists"),
    },
};

export const ENTITY_TYPE_CODES_ALL = {
    ...ENTITY_TYPE_CODES_FE,
    MLTV2: {
        value: "MLTV2",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    MLTV3: {
        value: "MLTV3",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    MLTV4: {
        value: "MLTV4",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    MLTV5: {
        value: "MLTV5",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    MLTV6: {
        value: "MLTV6",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    JLTV: {
        value: "JLTV",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    JLTV2: {
        value: "JLTV2",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
    LTV: {
        value: "LTV",
        subFilter: true,
        label: t("supported-table-import-type.label.company-parameter"),
    },
};

export const hasSubFilter = entityType =>
    !!ENTITY_TYPE_CODES_ALL[entityType]?.subFilter;

export const REQUIRED_BY_ENTITY_TYPE = {
    P: ["sku"],
    PX: ["sku"],
    PL: ["sku"],
    PXREF: ["sku"],
    PCOMP: ["sku"],
    LPG: ["sku"],
    XLPG: ["sku", "key2"],
    XPLI: ["sku", "key2"],
    C: ["customerId"],
    CX: ["customerId"],
    SL: ["sellerId"],
    SX: ["sellerId"],
};
