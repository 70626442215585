import { Collapse, H4 } from "@/components/DesignSystem";
import React, { useEffect, useMemo, useState } from "react";
import "./CollapseGroup.styles.less";
// import { Collapse } from "./Collapse"; // Not working

// import { Collapse } from "@pricefx/unity-components";
// import { Collapse } from "@/components/DesignSystem/Collapse";

export const CollapseGroup = ({
    header,
    children,
    defaultCollapsed,
    collapsed: collapsedReactive = defaultCollapsed,
    indentedContent,
}) => {
    const defaultActiveKey = useMemo(
        () => (defaultCollapsed || collapsedReactive ? [] : ["1"]),
        [collapsedReactive, defaultCollapsed],
    );
    const [activeKey, setActiveKey] = useState(defaultActiveKey);
    useEffect(() => {
        setActiveKey(collapsedReactive ? [] : ["1"]);
    }, [collapsedReactive]);

    return (
        <Collapse
            ghost
            className="pm-collapse-group"
            activeKey={activeKey}
            onChange={key => {
                setActiveKey(key);
            }}
        >
            <Collapse.Panel
                key="1"
                header={<H4 style={{ lineHeight: "20px" }}>{header}</H4>}
            >
                <div style={indentedContent ? { paddingLeft: "24px" } : {}}>
                    {children}
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};
