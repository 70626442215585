import { Forms, H3, H4, Text } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { DestinationFields } from "@/components/EventWorkflows/components/DestinationFields";
import { ActionFields } from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { PayloadFields } from "@/components/EventWorkflows/ListenerStepForm/PayloadFields";
import { t } from "@/translations";
import React from "react";

const ENABLED_OPTIONS = [
    {
        label: t("general.enabled"),
        value: true,
    },
    {
        label: t("general.disabled"),
        value: false,
    },
];

const FALLBACK_ACTIONS = {
    TERMINATE: "TERMINATE_WORKFLOW",
    SKIP: "SKIP_ACTION",
    CUSTOM: "CUSTOM_ACTION",
};

const FALLBACK_ACTION_OPTIONS = [
    {
        label: "Terminate the Workflow",
        value: FALLBACK_ACTIONS.TERMINATE,
    },
    {
        label: "Skip the Action",
        value: FALLBACK_ACTIONS.SKIP,
    },
    {
        label: "Custom Action",
        value: FALLBACK_ACTIONS.CUSTOM,
    },
];

export const ActionFallbackFields = ({
    prefixKey = "fallbackOptions",
    customActionPrefix = "customAction",
    accountId,
    isSingleSource,
    singleSource,
}) => {
    const fallback = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: `${prefixKey}.enabled`,
    });
    const fallbackAction = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: `${prefixKey}.fallbackAction`,
    });
    const fullCustomActionPrefixKey = [prefixKey, customActionPrefix]
        .filter(Boolean)
        .join(".");

    return (
        <>
            <H3>{t("event-wf.listener-form.steps.fallback.title")}</H3>
            <Text>{t("event-wf.listener-form.steps.fallback.perex")}</Text>
            <Forms.Fields.Radio
                required
                name={`${prefixKey}.enabled`}
                label={t("event-wf.listener-form.fallback.label")}
                tooltip={t("event-wf.listener-form.fallback.tooltip")}
                options={ENABLED_OPTIONS}
                validator={Forms.pmValidators.isRequired}
                initialValue={false}
            />
            {fallback && (
                <>
                    <Forms.Fields.InputNumber
                        name={`${prefixKey}.numberOfAttempts`}
                        label={t(
                            "event-wf.listener-form.numberOfAttempts.label",
                        )}
                        validator={Forms.validators.failOnFirst([
                            Forms.pmValidators.isRequired,
                            Forms.pmValidators.min(1),
                            Forms.pmValidators.max(2147483647),
                        ])}
                        initialValue={1}
                        tooltip={t(
                            "event-wf.listener-form.numberOfAttempts.tooltip",
                        )}
                        inputWidth="default"
                        width="default"
                    />
                    <Forms.Fields.InputNumber
                        name={`${prefixKey}.fallbackTimeout`}
                        label={t(
                            "event-wf.listener-form.fallbackTimeout.label",
                        )}
                        validator={orEmpty(Forms.pmValidators.min(0))}
                        addonAfter={"min"}
                        tooltip={t(
                            "event-wf.listener-form.fallbackTimeout.tooltip",
                        )}
                        inputWidth="default"
                        width="default"
                    />
                    <Forms.Fields.Radio
                        required
                        name={`${prefixKey}.fallbackAction`}
                        label={t("event-wf.listener-form.fallbackAction.label")}
                        tooltip={t(
                            "event-wf.listener-form.fallbackAction.tooltip",
                        )}
                        options={FALLBACK_ACTION_OPTIONS}
                        validator={Forms.pmValidators.isRequired}
                        initialValue={FALLBACK_ACTIONS.TERMINATE}
                    />
                    {fallbackAction === FALLBACK_ACTIONS.CUSTOM && (
                        <>
                            <H4>{t("event-wf.form.custom-action")}</H4>
                            {/* <H4>{t("event-wf.form.target")}</H4> */}
                            <DestinationFields
                                accountId={accountId}
                                isSingleSource={isSingleSource}
                                singleSource={singleSource}
                                destinationTypeName={`${fullCustomActionPrefixKey}.${DestinationFields.fieldNames.destinationType}`}
                                destinationIdName={`${fullCustomActionPrefixKey}.${DestinationFields.fieldNames.destinationId}`}
                            />
                            {/* <H4>{t("event-wf.form.action")}</H4> */}
                            <ActionFields
                                accountId={accountId}
                                destinationTypeName={`${fullCustomActionPrefixKey}.${DestinationFields.fieldNames.destinationType}`}
                                destinationIdName={`${fullCustomActionPrefixKey}.${DestinationFields.fieldNames.destinationId}`}
                                targetTypeName={`${fullCustomActionPrefixKey}.targetType`}
                                targetIdName={`${fullCustomActionPrefixKey}.targetId`}
                            />
                            <PayloadFields
                                heading={t(
                                    "event-wf.listener-form.fallback-payload",
                                )}
                                prefixKey={`${fullCustomActionPrefixKey}.payload`}
                                checkboxName={`${fullCustomActionPrefixKey}.useOriginalPayload`}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};
