import React from "react";
import { isLoading } from "@/modules/loadable";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";

const LoadableText = ({
    title,
    fallbackText = title,
    useQuery,
    queryParams,
    getText,
}) => {
    const query = useQuery(queryParams);
    const textMaybe = getText({ query, queryParams });

    if (textMaybe) return <TextOrLoadableText text={textMaybe} title={title} />;
    else if (isLoading(query))
        return <TextOrLoadableText text={<RowSkeleton />} title={title} />;
    else return <TextOrLoadableText text={fallbackText} hidden={false} />;
};

export const TextOrLoadableText = ({
    text,
    title,
    hidden,
    useQuery,
    queryParams,
    getText,
}) => {
    if (text) return <span title={title}>{text}</span>;
    if (hidden) return null;

    return (
        <LoadableText
            useQuery={useQuery}
            queryParams={queryParams}
            getText={getText}
            title={title}
        />
    );
};
