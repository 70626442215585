import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { EnableEntity } from "@/components/ISVMapping/steps/vendor-entities/Enable.entity";
import { PricefxEntity } from "@/components/ISVMapping/steps/vendor-entities/Pricefx.entity";
import React from "react";

export const ISV_VENDOR = {
    PRICEFX: "PRICEFX",
    ENABLE: "ENABLE",
};

const vendorEntities = {
    [ISV_VENDOR.ENABLE]: {
        [MAPPER_DIRECTION.DOWNLOAD]: EnableEntity,
        [MAPPER_DIRECTION.UPLOAD]: EnableEntity,
    },
    [ISV_VENDOR.PRICEFX]: PricefxEntity,
};
export const VendorEntity = ({ vendor, direction, ...rest }) => {
    const Component =
        vendorEntities[vendor]?.[direction] ?? vendorEntities[vendor];
    if (!Component) return null;

    return <Component direction={direction} {...rest} />;
};
