import { Button, Forms, Gap, H4, Text } from "@/components/DesignSystem";
import { ReadOnlyProvider } from "@/components/DesignSystem/Forms/ReadOnlyContext";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import {
    TIMING_DATETIME_FIELDS,
    TIMING_TIME_FIELDS,
    TimingFields,
} from "@/components/EventSchedulers/TimingFields";
import { useSchedulerNameExistsValidation } from "@/components/EventSchedulers/validators";
import { DestinationFields } from "@/components/EventWorkflows/components/DestinationFields";
import { ActionFields } from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { t } from "@/translations";
import { flatten, unflatten } from "flat";
import { mapValues } from "lodash";
import { pipe, prop } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { CollapseGroup } from "../DesignSystem/Collapse/CollapseGroup";

const COLUMNS = [
    {
        name: "name",
        label: "Name",
        type: fieldTypes.TEXT,
        fieldProps: {
            required: true,
            unique: true,
        },
    },
    {
        name: "value",
        label: "Value",
        type: fieldTypes.TEXT,
        fieldProps: {
            required: true,
        },
    },
];

export const PayloadExtraFields = ({ headersName, propertiesName }) => {
    return (
        <>
            <Forms.Fields.EntityEditorTable
                name={headersName}
                columns={COLUMNS}
                title={t("event-scheduler.group.payload.headers")}
                addButtonProps={{
                    label: t("event-scheduler.form.headers.add-header"),
                }}
                addModalProps={{
                    title: t("event-scheduler.form.headers.add-header"),
                    submitButtonLabel: t("general.add"),
                }}
                editModalProps={{
                    title: t("event-scheduler.form.headers.edit-header"),
                    submitButtonLabel: t("general.save"),
                }}
                confirmRemove={{
                    title: "Delete Header",
                    message: "Do you want to delete this Header?",
                }}
                // rowHeight={36}
                // width="default"
                // inputWidth="default"
            />
            <Forms.Fields.EntityEditorTable
                name={propertiesName}
                columns={COLUMNS}
                title={t("event-scheduler.group.payload.properties")}
                addButtonProps={{
                    label: t("event-scheduler.form.properties.add-property"),
                }}
                addModalProps={{
                    title: t("event-scheduler.form.properties.add-property"),
                    submitButtonLabel: t("general.add"),
                }}
                editModalProps={{
                    title: t("event-scheduler.form.properties.edit-property"),
                    submitButtonLabel: t("general.save"),
                }}
                confirmRemove={{
                    title: "Delete Property",
                    message: "Do you want to delete this Property?",
                }}
                // rowHeight={36}
            />
        </>
    );
};

export const isPOJO = arg => {
    if (arg == null || typeof arg !== "object") {
        return false;
    }
    const proto = Object.getPrototypeOf(arg);
    if (proto == null) {
        return true; // `Object.create(null)`
    }
    return proto === Object.prototype;
};

const getPayloadName = name => `payload.${name}`;

export const fromEOSch = values => {
    const formatted = mapValues(values, (v, k, o) => {
        if (TIMING_DATETIME_FIELDS.includes(k) && v)
            return v.format(fromEOSch.DATETIME_FORMAT);
        if (TIMING_TIME_FIELDS.includes(k) && v) return v.format("HH:mm");
        else return v;
    });
    const nestedValues = unflatten(formatted);
    return nestedValues;
};
fromEOSch.DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";
fromEOSch.TIME_FORMAT = "HH:mm";

const to = initialValues => {
    const flat = flatten(initialValues, { maxDepth: 2 });
    const parsed = mapValues(flat, (v, k, o) => {
        if (TIMING_DATETIME_FIELDS.includes(k) && v) return moment(v);
        if (TIMING_TIME_FIELDS.includes(k) && v) return moment(v, "HH:mm");
        else return v;
    });
    return parsed;
};

export const NAME_VALIDATION_PATTERN = new RegExp(/^[a-zA-Z0-9._-]+$/);

export const EventSchedulerForm = ({
    initialValues: _initialValues,
    accountId,
    onSubmit,
    onCancel,
    readOnly,
}) => {
    const initialValues = useMemo(
        () => _initialValues && to(_initialValues),
        [_initialValues],
    );
    const isNew = !initialValues?.id;
    const { formId, handleSubmit, setValues, getBag } = Forms.useForm({
        initialValues,
        onSubmit: pipe(prop("values"), fromEOSch, onSubmit),
    });
    const destinationType = DestinationFields.useDestinationType({ formId });
    const destinationId = DestinationFields.useDestinationId({ formId });
    const isPartition = destinationType === "PARTITION";
    const payloadValidator = isPartition
        ? orEmpty(Forms.pmValidators.isValidJSON)
        : undefined;

    const skipValidation = useCallback(
        ({ name, destinationType, destinationId }) => {
            const incompleteParams = !destinationType || !destinationId;
            const targetMatch =
                destinationType === initialValues?.destinationType &&
                destinationId === initialValues?.destinationId;
            const isExistingEdit =
                !isNew && name === initialValues?.name && targetMatch;

            return incompleteParams || isExistingEdit;
        },
        [
            initialValues?.destinationId,
            initialValues?.destinationType,
            initialValues?.name,
            isNew,
        ],
    );

    const nameExistsValidation = useSchedulerNameExistsValidation({
        accountId,
        skipValidation,
        destinationType,
        destinationId,
    });

    return (
        <ReadOnlyProvider readOnly={readOnly}>
            <Text>{t("event-scheduler.form.perex")}</Text>
            <Gap />
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <CollapseGroup
                    header={t("event-scheduler.group.general.label")}
                    indentedContent
                >
                    <Forms.Fields.Radio
                        name="enabled"
                        label={t("event-scheduler.form.enabled.label")}
                        layout="vertical"
                        // horizontal
                        // noMaxWidth
                        tooltip={t("event-scheduler.form.enabled.tooltip")}
                        required
                        validator={Forms.pmValidators.isRequired}
                        options={[
                            { label: t("general.enabled"), value: true },
                            { label: t("general.disabled"), value: false },
                        ]}
                        initialValue
                    />
                    <Forms.Fields.Input
                        name="name"
                        label={t("event-scheduler.form.name.label")}
                        required
                        disabled={!isNew}
                        validator={
                            isNew
                                ? Forms.validators.failOnFirst([
                                      Forms.pmValidators.isRequired,
                                      Forms.pmValidators.createMinLengthValidation(
                                          1,
                                      ),
                                      Forms.pmValidators.createPatternValidator(
                                          NAME_VALIDATION_PATTERN,
                                          "Name has a wrong format. Valid format is a-z A-Z, 0-9 . _ - without spaces",
                                      ),
                                      nameExistsValidation,
                                  ])
                                : undefined
                        }
                    />
                </CollapseGroup>

                <CollapseGroup
                    header={t("event-scheduler.group.target.label")}
                    indentedContent
                >
                    <DestinationFields accountId={accountId} />
                </CollapseGroup>

                <CollapseGroup
                    header={t("event-scheduler.group.action.label")}
                    indentedContent
                >
                    <ActionFields
                        accountId={accountId}
                        setValues={setValues}
                        targetTypeName="destinationTargetType"
                        targetIdName="destinationTargetId"
                    />
                </CollapseGroup>

                <CollapseGroup
                    header={t("event-scheduler.group.payload.label")}
                    indentedContent
                >
                    <H4>{t("event-scheduler.group.payload.body")}</H4>
                    <Forms.Fields.TextArea
                        name={getPayloadName("payload")}
                        // checkboxName={getPayloadName("useEventBodyAsPayload")}
                        label={t("event-scheduler.form.body.label")}
                        width="max"
                        rows="5"
                        initialValue=""
                        validator={payloadValidator}
                    />
                    {!isPartition && (
                        <PayloadExtraFields
                            headersName={getPayloadName("headers")}
                            propertiesName={getPayloadName("properties")}
                        />
                    )}
                </CollapseGroup>

                <CollapseGroup
                    header={t("event-scheduler.group.timing.label")}
                    indentedContent
                >
                    <TimingFields
                        accountId={accountId}
                        getBag={getBag}
                        lastExecution={
                            initialValues?.lastExecution
                                ? moment
                                      .utc(initialValues?.lastExecution)
                                      .local()
                                : undefined
                        }
                    />
                </CollapseGroup>

                <Gap />
                <Button
                    visible={!readOnly}
                    data-test={isNew ? "create-button" : "save-button"}
                    htmlType="submit"
                    type="primary"
                    formId={formId}
                    label={isNew ? t("general.create") : t("general.save")}
                    style={{ marginLeft: 24 }}
                />
                <Button
                    data-test="cancel-button"
                    htmlType="button"
                    type="text"
                    onClick={onCancel}
                    label={readOnly ? t("general.back") : t("general.cancel")}
                    disabled={false}
                />
            </Forms.Form>
        </ReadOnlyProvider>
    );
};

EventSchedulerForm.propTypes = {
    initialValues: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
