import { AlertCellWithPopover } from "@/components/AlertCellWithPopover/AlertCellWithPopover";
import { idleLoadableProps } from "@/components/DataUploads/Wizard/loadables";
import { Alert, Forms, H2, P, Text } from "@/components/DesignSystem";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { addLocalFilteredAlerts } from "@/components/EventSchedulers/EventSchedulers.page";
import { DestinationFields } from "@/components/EventWorkflows/components/DestinationFields";
import { ListenerActionStep } from "@/components/EventWorkflows/ListenerStepForm/ListenerAction.step";
import {
    SourceEventModal,
    getOptionLabelKey,
    getOptionLabelMaybe,
    useLoadableOptionsValidatorMaybe,
} from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    useEOTActionEntitiesOptionsQuery,
    useEOTActionTypesOptionsQuery,
    useEOTEntitiesOptionsQuery,
} from "@/components/EventWorkflows/loadables";
import { getLoadableSelectProps } from "@/components/Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { constant, identity, noop } from "lodash/fp";
import React, { useCallback, useMemo } from "react";
import { TextOrLoadableText } from "./LoadableText";
import { CollapseGroup } from "@/components/DesignSystem/Collapse/CollapseGroup";

const GeneralStep = ({ allStepsProps: { existingNames } }) => {
    return (
        <>
            <H2>{t("event-wf.listener-form.steps.general.title")}</H2>
            <Text>{t("event-wf.listener-form.steps.general.perex")}</Text>
            <Forms.FieldGroup>
                <Forms.Fields.Input
                    name="name"
                    label={t("event-wf.listener-form.name.label")}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createForbidValuesValidation(
                            existingNames,
                            "Already exists",
                        ),
                    ])}
                />
                <Forms.Fields.TextArea
                    name="description"
                    label={t("event-wf.listener-form.description.label")}
                    rows="3"
                />
                <Forms.Fields.InputNumber
                    name="timeoutInMinutes"
                    label={t("event-wf.listener-form.timeoutInMinutes.label")}
                    validator={Forms.validators.failOnFirst([
                        orEmpty(Forms.pmValidators.min(1)),
                    ])}
                    addonAfter={"min"}
                    tooltip={t(
                        "event-wf.listener-form.timeoutInMinutes.tooltip",
                    )}
                    inputWidth="default"
                    width="default"
                />
            </Forms.FieldGroup>
        </>
    );
};

export const STEP_SOURCE_EVENT_LOGIC = {
    ALL: "ALL",
    ONE_OF: "ONE_OF",
};

const COMBINATION_OPTIONS = [
    {
        label: t("event-wf.listener-form.combinationLogic.all.label"),
        value: STEP_SOURCE_EVENT_LOGIC.ALL,
    },
    {
        label: t("event-wf.listener-form.combinationLogic.oneOf.label"),
        value: STEP_SOURCE_EVENT_LOGIC.ONE_OF,
    },
];

const SourceStep = ({
    allStepsProps: {
        accountId,
        isFirstListener,
        isSingleSource,
        singleSource,
        eoTypesQuery,
        showOrderWarning,
    },
}) => {
    const formId = Forms.useFormId();
    const sourceEventsValue = Forms.useFieldValue({
        formId,
        name: "sourceEvents",
    });
    const combinationLogicValue = Forms.useFieldValue({
        formId,
        name: "combinationLogic",
    });
    const skipEventsValue = Forms.useFieldValue({
        formId,
        name: "skipEvents",
    });

    const eventsColumns = useMemo(
        () => [
            {
                name: "sourceType",
                label: t("event-wf.listener-form.sourceType.label"),
                type: fieldTypes.TEXT,
            },
            {
                name: getOptionLabelKey("sourceId"),
                label: t("event-wf.listener-form.sourceId.label"),
                type: fieldTypes.TEXT,
                render: (_text, record) =>
                    record.invalid ? (
                        <AlertCellWithPopover
                            popoverTitle={"No longer exists"}
                            popoverContent={
                                "The source does not exist anymore."
                            }
                            content={"No longer exists"}
                        />
                    ) : (
                        <TextOrLoadableText
                            text={record.sourceName}
                            title={record.sourceId}
                            hidden={!record.sourceId}
                            useQuery={useEOTEntitiesOptionsQuery}
                            queryParams={{
                                accountId,
                                eoType: record.sourceType,
                                canFetch:
                                    !!record.sourceType && !!record.sourceId,
                            }}
                            getText={({ query }) =>
                                getOptionLabelMaybe(
                                    record.sourceId,
                                    query.loadable,
                                )
                            }
                        />
                    ),
            },
            {
                name: "sourceEventType",
                label: t("event-wf.listener-form.sourceEventType.label"),
                type: fieldTypes.TEXT,
            },
            {
                name: "jsonPath",
                label: t("event-wf.listener-form.filter.jsonPath.label"),
                type: fieldTypes.TEXT,
                canFilter: false,
                canSort: false,
                render: (text, record) =>
                    record.filters?.map(({ jsonPath }) => jsonPath)?.join(","),
            },
            {
                name: "value",
                label: t("event-wf.listener-form.filter.value.label"),
                type: fieldTypes.TEXT,
                canFilter: false,
                canSort: false,
                render: (text, record) =>
                    record.filters?.map(({ value }) => value)?.join(","),
            },
        ],
        [accountId],
    );
    const modalExtraProps = {
        accountId,
        eoTypesQuery,
        isSingleSource,
        singleSource,
    };

    return (
        <>
            <H2>{t("event-wf.listener-form.steps.source.title")}</H2>
            <Text>{t("event-wf.listener-form.steps.source.perex")}</Text>
            <Forms.Fields.EntityEditorTable
                name="sourceEvents"
                title={t("event-wf.listener-form.sourceEvents.label")}
                addButtonProps={{
                    label: t("event-wf.listener-form.sourceEvents.add-button"),
                }}
                columns={eventsColumns}
                ModalComponent={SourceEventModal}
                getModalExtraProps={() => ({
                    ...modalExtraProps,
                    isSkipEvents: false,
                })}
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired, // in the future: Wait for the event from the previous step cannot be selected, at least one Source Event must be added.
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                    }),
                ])}
                addAlertFn={addLocalFilteredAlerts([
                    {
                        prop: getOptionLabelKey("sourceId"),
                        getVisible: ({ invalid }) => invalid,
                        getType: constant("RED"),
                        getTooltip: constant(t("general.invalid")),
                    },
                ])}
            />
            <CollapseGroup
                collapsed={!skipEventsValue?.length}
                header={t("event-wf.listener-form.skipEvents.label")}
            >
                <Text>{t("event-wf.listener-form.skipEvents.perex")}</Text>
                <Forms.Fields.EntityEditorTable
                    name="skipEvents"
                    addButtonProps={{
                        label: t(
                            "event-wf.listener-form.skipEvents.add-button",
                        ),
                    }}
                    columns={eventsColumns}
                    ModalComponent={SourceEventModal}
                    getModalExtraProps={() => ({
                        ...modalExtraProps,
                        isSkipEvents: true,
                    })}
                    addAlertFn={addLocalFilteredAlerts([
                        {
                            prop: getOptionLabelKey("sourceId"),
                            getVisible: ({ invalid }) => invalid,
                            getType: constant("RED"),
                            getTooltip: constant(t("general.invalid")),
                        },
                    ])}
                />
            </CollapseGroup>

            {sourceEventsValue?.length >= 2 && (
                <Forms.Fields.Radio
                    required
                    name="combinationLogic"
                    label={t("event-wf.listener-form.combinationLogic.label")}
                    options={COMBINATION_OPTIONS}
                    initialValue={STEP_SOURCE_EVENT_LOGIC.ALL}
                    validator={Forms.pmValidators.isRequired}
                    tooltip={t(
                        "event-wf.listener-form.combinationLogic.tooltip",
                    )}
                />
            )}
            {combinationLogicValue === STEP_SOURCE_EVENT_LOGIC.ALL && (
                <Forms.Fields.InputNumber
                    name="_stepTriggerTimeoutInMinutes"
                    label={t("event-wf.listener-form.timeout.label")}
                    validator={orEmpty(Forms.pmValidators.min(1))}
                    addonAfter={"min"}
                    tooltip={t("event-wf.listener-form.timeout.tooltip")}
                />
            )}
            <Forms.Fields.InputNumber
                name="delayedTriggerInMinutes"
                label={t("event-wf.listener-form.timein.label")}
                validator={orEmpty(Forms.pmValidators.min(1))}
                addonAfter={"min"}
                tooltip={t("event-wf.listener-form.timein.tooltip")}
            />
            {!isFirstListener && (
                <Forms.Fields.Checkbox
                    name="validatePreviousStepAsyncTaskId"
                    label={t(
                        "event-wf.listener-form.validatePreviousStepAsyncTaskId.label",
                    )}
                />
            )}
            {showOrderWarning && (
                <Alert
                    type="warning"
                    message={
                        "The order of the listeners has been changed. Check the listener settings carefully."
                    }
                />
            )}
        </>
    );
};

// TODO: extract and rename to TargetFields
export const ActionFields = ({
    accountId,
    setValues = noop,
    targetTypeName = "targetType",
    targetIdName = "targetId",
    destinationTypeName = DestinationFields.fieldNames.destinationType,
    destinationIdName = DestinationFields.fieldNames.destinationId,
}) => {
    const { wfId } = useAccountAppParams();
    const formId = Forms.useFormId();
    const destinationType = Forms.useFieldValue({
        formId,
        name: destinationTypeName,
    });
    const eotTargetActionTypesQuery = useEOTActionTypesOptionsQuery({
        accountId,
        eoType: destinationType,
        canFetch: !!destinationType,
    });

    const destinationTargetType = Forms.useFieldValue({
        formId,
        name: targetTypeName,
    });
    const destinationId = Forms.useFieldValue({
        formId,
        name: destinationIdName,
    });
    const missingActionsDeps = [
        [destinationType, "event-wf.listener-form.destinationType.label"],
        [
            destinationTargetType,
            "event-wf.listener-form.destinationTargetType.label",
        ],
        [destinationId, "event-wf.listener-form.destinationId.label"],
    ]
        .filter(([value, tsKey]) => !value)
        .map(([value, tsKey]) => t(tsKey));
    const eotTargetActionEntitiesQuery = useEOTActionEntitiesOptionsQuery({
        accountId,
        eoType: destinationType,
        entityId: destinationId,
        wfId,
        actionType: destinationTargetType,
        canFetch: !missingActionsDeps.length,
    });
    const handleTypeChange = useCallback(() => {
        setValues({ [targetIdName]: null });
    }, [setValues, targetIdName]);

    return (
        <>
            <Forms.Fields.Select
                name={targetTypeName}
                label={t("event-wf.listener-form.destinationTargetType.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    useLoadableOptionsValidatorMaybe({
                        isRequired: true,
                        loadable: eotTargetActionTypesQuery.loadable,
                    }),
                ])}
                onChange={handleTypeChange}
                {...getLoadableSelectProps(eotTargetActionTypesQuery.loadable)}
                {...idleLoadableProps(
                    eotTargetActionTypesQuery.loadable,
                    "Please, select type",
                )}
            />
            <Forms.Fields.Select
                name={targetIdName}
                label={t("event-wf.listener-form.destinationTargetId.label")}
                required
                validator={Forms.validators.failOnFirst([
                    Forms.pmValidators.isRequired,
                    useLoadableOptionsValidatorMaybe({
                        isRequired: true,
                        loadable: eotTargetActionEntitiesQuery.loadable,
                    }),
                ])}
                {...getLoadableSelectProps(
                    eotTargetActionEntitiesQuery.loadable,
                )}
                {...idleLoadableProps(
                    eotTargetActionEntitiesQuery.loadable,
                    `Please, select ${missingActionsDeps.join(", ")}`,
                )}
            />
        </>
    );
};

export const LISTENER_STEP_KEY = {
    GENERAL: "GENERAL",
    SOURCE: "SOURCE",
    ACTION: "ACTION",
    // FALLBACK: "FALLBACK",
};

export const listenerStepFormSteps = [
    {
        key: LISTENER_STEP_KEY.GENERAL,
        title: t("event-wf.listener-form.steps.general.title"),
        Component: GeneralStep,
    },
    {
        key: LISTENER_STEP_KEY.SOURCE,
        title: t("event-wf.listener-form.steps.source.title"),
        Component: SourceStep,
    },
    {
        key: LISTENER_STEP_KEY.ACTION,
        title: t("event-wf.listener-form.steps.action.title"),
        Component: ListenerActionStep,
    },
    // TODO: rm, moved to ListenerAction.step
    // {
    //     key: LISTENER_STEP_KEY.FALLBACK,
    //     title: t("event-wf.listener-form.steps.fallback.title"),
    //     Component: FallbackStep,
    // },
];
