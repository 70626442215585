import { Forms, H4 } from "@/components/DesignSystem";
import { CollapseContent } from "@/components/DesignSystem/Collapse/CollapseContent";
import { PropertyTable as PropertyTableAsField } from "@/components/DesignSystem/Forms/fields/PropertyTable";
import { ErrorBoundary } from "@/components/Error";
import { t } from "@/translations";
import { Collapse } from "@pricefx/unity-components";
import React from "react";

const COLLAPSE_KEYS = {
    BODY: "BODY",
    HEADERS: "HEADERS",
    PROPERTIES: "PROPERTIES",
};

const PayloadBodyFields = ({
    name,
    label = t("event-wf.listener-form.payload-content"),
    checkboxName,
    checkboxTooltip = "If selected, the payload received in the body of the event will be sent as a parameter of the triggered action.",
}) => {
    const useEventBodyAsPayload = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: checkboxName,
    });

    return (
        <>
            <Forms.Fields.Checkbox
                name={checkboxName}
                label="Use the original event payload as the body"
                tooltip={checkboxTooltip}
            />
            <Forms.Fields.TextArea
                name={name}
                label={label}
                width="max"
                disabled={useEventBodyAsPayload}
                // validator={Forms.validators.failOnFirst([Forms.pmValidators.isRequired])}
                rows="5"
                initialValue=""
            />
        </>
    );
};

export const PayloadFields = ({
    heading = t("event-wf.form.payload"),
    payloadBodyLabel,
    prefixKey = "",
    getName = sub => [prefixKey, sub].filter(Boolean).join("."),
    bodyName = getName("payload"),
    checkboxName = getName("useOriginalPayload"),
    headersName = getName("headers"),
    propertiesName = getName("properties"),
}) => {
    // Separate deprioritized ticket
    return (
        null && (
            <>
                <H4>{heading}</H4>
                <Collapse
                    bordered={false}
                    defaultActiveKey={
                        [
                            // COLLAPSE_KEYS.BODY,
                            // COLLAPSE_KEYS.HEADERS,
                            // COLLAPSE_KEYS.PROPERTIES,
                        ]
                    }
                    // size={4}
                >
                    <Collapse.Panel header="Body" key={COLLAPSE_KEYS.BODY}>
                        <CollapseContent>
                            <PayloadBodyFields
                                name={bodyName}
                                label={payloadBodyLabel}
                                checkboxName={checkboxName}
                                checkboxTooltip={
                                    "If selected, the payload received in the body of the event will be sent as a parameter of the triggered action. If combination 'all' selected, use last event payload"
                                }
                            />
                        </CollapseContent>
                    </Collapse.Panel>
                    <Collapse.Panel
                        header="Headers"
                        key={COLLAPSE_KEYS.HEADERS}
                    >
                        <CollapseContent>
                            <ErrorBoundary>
                                <PropertyTableAsField
                                    name={headersName}
                                    addButtonLabel={"Add Header"}
                                    width="max"
                                />
                            </ErrorBoundary>
                        </CollapseContent>
                    </Collapse.Panel>
                    <Collapse.Panel
                        header="Properties"
                        key={COLLAPSE_KEYS.PROPERTIES}
                    >
                        <CollapseContent>
                            <ErrorBoundary>
                                <PropertyTableAsField
                                    name={propertiesName}
                                    addButtonLabel={"Add Property"}
                                    width="max"
                                />
                            </ErrorBoundary>
                        </CollapseContent>
                    </Collapse.Panel>
                </Collapse>
            </>
        )
    );
};
