import SFTPServerInfo from "@/components/DataUploads/SftpManagement/SFTPServerInfo.component";
import { Gap, H5, Text } from "@/components/DesignSystem";
import { sftpEntityType } from "@/services/dataLoadSftp.service";
import { t } from "@/translations";
import React from "react";

export const PricefxStep = ({ stepProps }) => {
    return (
        <>
            <H5>{t("isv-connections.steps.success.title")}</H5>
            <Text size="small">{t("isv-connections.steps.success.perex")}</Text>
            <Gap />
            <SFTPServerInfo
                dataLoadId={stepProps.isvConnectionId}
                type={sftpEntityType.ISV_ENABLE}
            />
        </>
    );
};
