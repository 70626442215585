import { useDic } from "@/components/Dic/useDic.hook";
import ElasticSearchPanel from "@/components/Integrations/Dashboard/Tabs/ElasticSearchPanel";
import { EntityType } from "@/constants/CommonConstats";
import { EVENT_WF_RUN_HISTORY_LOG_LIST } from "@/constants/sessionState.constants";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React from "react";

const DEFAULT_COLUMNS = {
    "@timestamp": true,
    message: true,
    entityType: true,
    entityName: true,
    stepName: true,
    level: true,
    destinationType: true,
    destinationName: true,
    destinationId: true,
    targetType: true,
    targetId: true,
    targetLabel: true,
};
export const RunHistoryLogs = ({ accountId, initialQuery, visible }) => {
    const { axiosService } = useDic();
    const fetchData = params =>
        axiosService
            .post(`/api/accounts/${accountId}/eo-event-logs`, params)
            .then(getData);

    return (
        <ElasticSearchPanel
            fetchData={fetchData}
            defaultColumns={DEFAULT_COLUMNS}
            entityId={accountId}
            entityType={EntityType.PROJECT}
            sessionStateName={EVENT_WF_RUN_HISTORY_LOG_LIST}
            initialQuery={initialQuery}
            visible={visible}
        />
    );
};

RunHistoryLogs.propTypes = {
    accountId: PropTypes.number.isRequired,
    initialQuery: PropTypes.string,
};
