import { ButtonMenu, Gap, Modal, UnityLayout } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { Text } from "@/components/DesignSystem/Text/Text";
import { useDic } from "@/components/Dic/useDic.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { SecurityContext } from "@/security/authorization";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import { buildPageableUrl } from "@/utils/urlUtils";
import React, { useCallback, useContext, useState } from "react";
import { WorkflowType } from "../../constants/CommonConstats";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { useFetchPage } from "../PagableTable/useFetchPage.hook";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";
import { approvalsColumns } from "./ApprovalsTableColumns";
import { WorkflowApprovalDetailPanel } from "./components/WorkflowApprovalDetailPanel";

const preferencesType = preferencesTypes.WORK_FLOW_APPROVALS_TABLE;

export const ApprovalType = {
    APPROVED: "APPROVED",
    DECLINED: "DECLINED",
};

export function WorkflowApprovals() {
    const { messageService, axiosService } = useDic();
    const securityContext = useContext(SecurityContext);
    const [modalProps, setModalProps] = useState({});

    const workflowApprovalDetail = useDetailDrawerState();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            axiosService.post(
                buildPageableUrl(`/api/workflow-approvals`, page, size, sort),
                filter,
            ),
        [],
    );

    const sendMessage = useCallback(
        (record, event, successMessage) => {
            axiosService
                .post(`/api/workflow-approvals/send-message`, {
                    id: record.id,
                    event,
                })
                .then(() => {
                    reload();
                    messageService.success({
                        content: successMessage,
                    });
                })
                .catch(e =>
                    messageService.error({
                        content: getErrorMessage(e.response.data),
                    }),
                );
        },
        [messageService, reload],
    );

    const modalAction = useCallback(
        (record, event, successMessage) => {
            sendMessage(record, event, successMessage);
            setModalProps({});
        },
        [sendMessage],
    );

    const onModalCancel = useCallback(() => {
        setModalProps({});
    }, []);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.show-detail"),
                        onClick: workflowApprovalDetail.show,
                    },
                    {
                        title: t("general.approve"),
                        onClick: () =>
                            setModalProps({
                                visible: true,
                                title: t(
                                    "account.workflows.confirm-workflow.title-approve",
                                ),
                                okText: t("general.approve"),
                                message: t(
                                    "account.workflows.confirm.workflow.message-approve",
                                    {
                                        user: securityContext?.user?.fullName,
                                        action: WorkflowType.translate(
                                            record.type,
                                        ),
                                        account: record.account,
                                        assetName: record.asset,
                                    },
                                ),
                                question: t(
                                    "account.workflows.confirm.workflow.message-approve-question",
                                ),
                                onAction: () =>
                                    modalAction(
                                        record,
                                        ApprovalType.APPROVED,
                                        t("general.approved"),
                                    ),
                            }),
                    },
                    {
                        title: t("general.reject"),
                        onClick: () =>
                            setModalProps({
                                visible: true,
                                title: t(
                                    "account.workflows.confirm-workflow.title-reject",
                                ),
                                okText: t("general.reject"),
                                message: t(
                                    "account.workflows.confirm.workflow.message-reject",
                                    {
                                        user: securityContext?.user?.fullName,
                                        action: WorkflowType.translate(
                                            record.type,
                                        ),
                                        account: record.account,
                                        assetName: record.asset,
                                    },
                                ),
                                question: t(
                                    "account.workflows.confirm.workflow.message-reject-question",
                                ),
                                onAction: () =>
                                    modalAction(
                                        record,
                                        ApprovalType.DECLINED,
                                        t("general.rejected"),
                                    ),
                            }),
                    },
                ]}
            />
        ),
        [sendMessage],
    );

    return (
        <>
            <Modal visible={modalProps.visible} onCancel={onModalCancel}>
                <UnityLayout>
                    <UnityLayout.Header size={3} title={modalProps.title} />
                    <UnityLayout.Content padding={[true, true]}>
                        <Text dataTest="workflow-info">
                            {modalProps.message}
                        </Text>
                        <Gap />
                        <Text dataTest="workflow-confirm">
                            {modalProps.question}
                        </Text>
                    </UnityLayout.Content>
                    <UnityLayout.Footer
                        actionButtons={
                            <ButtonMenu
                                buttons={[
                                    {
                                        label: modalProps.okText,
                                        onClick: modalProps.onAction,
                                        type: "primary",
                                    },
                                    {
                                        label: t("general.cancel"),
                                        onClick: onModalCancel,
                                        type: "text",
                                    },
                                ]}
                            />
                        }
                    />
                </UnityLayout>
            </Modal>
            <TableWithPreferencesManagement
                actionMenu={actionMenu}
                columns={approvalsColumns}
                defaultSort={{
                    fieldName: "workflowRequestData.createdAt",
                    sortDir: "descending",
                }}
                fixed
                rowKey="id"
                datasetSlicing="server"
                preferencesType={preferencesType}
                {...tableProps}
            />
            <WorkflowApprovalDetailPanel
                record={workflowApprovalDetail.record}
                onClose={workflowApprovalDetail.hide}
            />
        </>
    );
}

WorkflowApprovals.propTypes = {};
