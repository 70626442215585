import { Forms } from "@/components/DesignSystem";
import { useIsvMapperNameValidator } from "@/components/ISVMapping/helpers";
import { t } from "@/translations";
import React from "react";

const { Fields, pmValidators } = Forms;

export const MAPPER_DIRECTION = {
    DOWNLOAD: "DOWNLOAD", // Pricefx => Vendor
    UPLOAD: "UPLOAD", // Vendor => Pricefx
};
const MAPPER_EMPTY_VALUE = {
    EMPTY_STRING: "EMPTY_STRING",
    CUSTOM_STRING: "CUSTOM_STRING",
};

const GeneralStep = ({ stepProps, allStepsProps }) => {
    const { vendorName, isvConnection } = allStepsProps;

    const isMapperNameValdator = useIsvMapperNameValidator({
        connectionId: isvConnection.id,
        initialValues: stepProps?.initialValues,
    });

    return (
        <div style={{ maxWidth: "426px" }}>
            <Fields.Input
                required
                name="name"
                label={t("isv-connections.data-mapping.step.general.name")}
                validator={isMapperNameValdator}
                inputWidth="max"
            />

            <Fields.Radio
                name="enabled"
                label={t("isv-connections.data-mapping.step.general.status")}
                initialValue={true}
                options={[
                    { label: t("general.active"), value: true },
                    { label: t("general.stopped"), value: false },
                ]}
            />

            <Fields.Radio
                required
                validator={pmValidators.isRequired}
                name="type"
                label={t("isv-connections.data-mapping.step.general.direction")}
                options={[
                    {
                        label: t(
                            "isv-connections.data-mapping.step.general.direction.pfx-vendor",
                            {
                                vendorName,
                            },
                        ),
                        value: MAPPER_DIRECTION.DOWNLOAD,
                    },
                    {
                        label: t(
                            "isv-connections.data-mapping.step.general.direction.vendor-pfx",
                            {
                                vendorName,
                            },
                        ),
                        value: MAPPER_DIRECTION.UPLOAD,
                    },
                ]}
            />
            <Fields.Radio
                required
                validator={pmValidators.isRequired}
                name="customEmptyStringValue"
                label={t(
                    "isv-connections.data-mapping.step.general.send-empty-value-as",
                )}
                options={[
                    {
                        label: t(
                            "isv-connections.data-mapping.step.general.empty-string",
                        ),
                        value: MAPPER_EMPTY_VALUE.EMPTY_STRING,
                    },
                    {
                        label: t(
                            "isv-connections.data-mapping.step.general.custom-string",
                        ),
                        value: MAPPER_EMPTY_VALUE.CUSTOM_STRING,
                    },
                ]}
            />
        </div>
    );
};

export default GeneralStep;
